import React from 'react';
import { getAllMediaByRelation } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    container,
    imageGallery,
    productDetails,
    description as descriptionClass,
    markdown,
    productAttributes,
} from './product-simple.module.scss';

import { ISection } from '../../models/section.model';
import { IProduct } from '../../models/product.model';
import { IMediaWithVariants } from '../../models/media-with-variants';
import { relations } from '../../config/relations';

import Section from '../hoc/section';
import ImageGallery from '../molecules/image-gallery';
import ProductDetailsSimple from '../organisms/product-details-simple';
import ProductAttributes from '../molecules/product-attributes';
import Markdown from '../hoc/markdown';

export interface IProductSection extends ISection {
    extendedItems: {
        product: IProduct;
    };
}

export interface IProductProps {
    className?: string;
    section: IProductSection;
    TitleTag?: React.ElementType;
}

export default function ProductSimple({ className = '', section, TitleTag }: IProductProps) {
    const { sectionId, css, style } = section;
    const { product } = section.extendedItems;
    const { attributes, description } = product;
    const galleryImages = createGalleryWithVariants(product);

    return (
        <Section
            sectionId={sectionId}
            className={[grid, className].join(' ')}
            css={css}
            style={style}
            classes={{ container }}
        >
            <ImageGallery className={imageGallery} media={galleryImages} />
            <ProductDetailsSimple
                className={productDetails}
                product={product}
                TitleTag={TitleTag}
            />
            <div className={descriptionClass}>
                <Markdown className={markdown}>{description}</Markdown>
            </div>
            {attributes.length > 0 && (
                <ProductAttributes className={productAttributes} attributes={attributes} />
            )}
        </Section>
    );
}

function createGalleryWithVariants(product: IProduct) {
    const variantsImages: IMediaWithVariants[] = [];

    const mainImages = getAllMediaByRelation(product.media, relations.galleryImage);

    product.variants?.forEach((variant) => {
        variant.media.forEach((image) => {
            variantsImages.push({ ...image, variantId: variant.variantId });
        });
    });

    return [...new Set([...mainImages, ...variantsImages])];
}
