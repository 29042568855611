// extracted by mini-css-extract-plugin
export var button = "product-details-simple-module--button--b7cd4";
export var finalPrice = "product-details-simple-module--final-price--9e73e";
export var finalPriceNet = "product-details-simple-module--final-price-net--a5d1f";
export var loader = "product-details-simple-module--loader--4c817";
export var manufacturer = "product-details-simple-module--manufacturer--feef5";
export var name = "product-details-simple-module--name--24d30";
export var priceError = "product-details-simple-module--price-error--9f5f8";
export var priceWrapper = "product-details-simple-module--price-wrapper--9f4e3";
export var priceWrapperNet = "product-details-simple-module--price-wrapper-net--c443c";
export var pricesContainer = "product-details-simple-module--prices-container--59073";
export var regularPrice = "product-details-simple-module--regular-price--908ac";
export var regularPriceNet = "product-details-simple-module--regular-price-net--b5979";
export var shortDescription = "product-details-simple-module--short-description--8dbe2";
export var variants = "product-details-simple-module--variants--c8a1b";