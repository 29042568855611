import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
    name as nameClass,
    manufacturer as manufacturerClass,
    loader,
    pricesContainer,
    priceWrapper,
    priceWrapperNet,
    finalPrice as finalPriceClass,
    finalPriceNet as finalNetPriceClass,
    regularPrice as regularPriceClass,
    regularPriceNet as regularNetPriceClass,
    shortDescription as shortDescriptionClass,
    priceError,
    variants,
    button,
} from './product-details-simple.module.scss';
import { IProduct } from '../../models/product.model';
import { getProductDynamicData } from '../../api-clients/product-price';
import { useProductContext } from '../../contexts/product-content';
import { useCart } from '../../hooks/use-cart';
import useTranslations from '../../hooks/use-translations';
import { getCartMutation } from '../../utils/get-cart-mutation';

import Title from '../atoms/title';
import Button from '../atoms/button';
import ProductVariantSelect from '../molecules/product-variant-select';
import PriceTag from '../molecules/price-tag';
import Loader from '../atoms/loader';
import Markdown from "../hoc/markdown";

export interface IProductDetailsProps {
    className?: string;
    product: IProduct;
    TitleTag?: React.ElementType;
}

export default function ProductDetailsSimple({
    className = '',
    product,
    TitleTag = 'h2',
}: IProductDetailsProps) {
    const t = useTranslations('ProductDetails');
    const { productId, name, shortDescription, manufacturer, canBuy } = product;

    const { activeVariant } = useProductContext();
    const { handleCartChange, isDisabled } = useCart();
    const { data, isLoading, isSuccess, isError } = useQuery({
        queryKey: ['product', productId],
        queryFn: getProductDynamicData,
    });
    const showGrossPrice = !!product.finalPrice.gross;
    const showNetPrice = !!product.finalPrice.net;
    const showTaxWord = showNetPrice && showNetPrice;

    const [productDynamicData, setProductDynamicData] = useState(
        extractActiveDataFromDynamicData(product, data)
    );

    const handleAddToCart = () => {
        if (!activeVariant) return;
        const mutation = getCartMutation(activeVariant.variantId, 'add', 1);
        handleCartChange(mutation);
    };

    useEffect(() => {
        setProductDynamicData(
            extractActiveDataFromDynamicData(product, data, activeVariant?.variantId)
        );
    }, [activeVariant, data, isLoading]);

    return (
        <div className={className}>
            <Title className={nameClass} Tag={TitleTag}>
                {name}
            </Title>
            <p className={manufacturerClass}>{manufacturer?.name}</p>
            {isLoading && <Loader className={loader} />}
            {isSuccess && (
                <div className={pricesContainer}>
                    {showGrossPrice && (
                        <PriceTag
                            className={priceWrapper}
                            finalPriceClassName={finalPriceClass}
                            regularPriceClassName={regularPriceClass}
                            finalPrice={productDynamicData.finalPrice}
                            regularPrice={productDynamicData.regularPrice}
                            taxWord={showTaxWord ? t.gross : ''}
                        />
                    )}
                    {showNetPrice && (
                        <PriceTag
                            className={priceWrapperNet}
                            finalPriceClassName={finalNetPriceClass}
                            regularPriceClassName={regularNetPriceClass}
                            finalPrice={productDynamicData.finalPrice}
                            regularPrice={productDynamicData.regularPrice}
                            taxWord={showTaxWord ? t.net : ''}
                            isNetPrice={true}
                        />
                    )}
                </div>
            )}
            {isError && <p className={priceError}>{t.priceFetchingError}</p>}
            {product.variants && product.options && product.variants.length > 0 && (
                <ProductVariantSelect
                    className={variants}
                    variants={product.variants}
                    options={product.options}
                />
            )}
            <Button
                className={button}
                isDisabled={!canBuy || !Number(productDynamicData.quantity)}
                isLoading={isDisabled}
                onClick={handleAddToCart}
                stylePreset={'main'}
            >
                {t.addToCart}
            </Button>
            <Markdown className={shortDescriptionClass}>{shortDescription}</Markdown>
        </div>
    );
}

function extractActiveDataFromDynamicData(
    product: IProduct,
    dynamicData:
        | Pick<IProduct, 'finalPrice' | 'regularPrice' | 'quantity' | 'variants'>
        | undefined,
    variantIndex?: number
) {
    if (!variantIndex && dynamicData) {
        return {
            quantity: dynamicData.quantity,
            regularPrice: dynamicData.regularPrice,
            finalPrice: dynamicData.finalPrice,
        };
    }

    const sourceData = dynamicData?.variants?.filter(
        (variant) => variant.variantId === variantIndex
    );

    if (sourceData) {
        return {
            quantity: sourceData[0].quantity,
            regularPrice: sourceData[0].regularPrice,
            finalPrice: sourceData[0].finalPrice,
        };
    }

    return {
        quantity: product.quantity,
        regularPrice: product.regularPrice,
        finalPrice: product.finalPrice,
    };
}
