import React, { useEffect } from 'react';
import ReactSelect from 'react-select';

import {
    container,
    selectLabel,
    selectWrapper,
    reactSelect,
} from './product-variant-select.module.scss';

import { IProductVariant } from '../../models/product-variant.model';
import { IProductOption, IProductOptionValue } from '../../models/product.model';

import { useProductContext } from '../../contexts/product-content';

export interface IProductVariantProps {
    className?: string;
    variants: IProductVariant[];
    options: IProductOption[];
}

export default function ProductVariantSelect({
    className = '',
    variants,
    options,
}: IProductVariantProps) {
    const { setActiveVariant, activeOptions, setActiveOptions } = useProductContext();

    useEffect(() => {
        if (activeOptions && !activeOptions.includes(undefined)) {
            const variantIds = activeOptions.map((option) => option?.variants);
            const result =
                variantIds.length > 0
                    ? variantIds.reduce((a, b) => a?.filter((c) => b?.includes(c)))
                    : false;
            if (result) {
                setActiveVariant(variants.filter((variant) => variant.variantId === result[0])[0]);
            }
        }
    }, [activeOptions]);

    const handleClick = (index: number, option: IProductOptionValue) => {
        setActiveOptions((prevOptions) => {
            if (prevOptions) {
                const newOptions = [...prevOptions];
                if (newOptions[index]?.valueId === option.valueId) {
                    newOptions[index] = undefined;
                } else {
                    newOptions[index] = option;
                }
                return newOptions;
            }

            return undefined;
        });
    };

    const possibleVariants = activeOptions?.map((option) =>
        option !== undefined ? option.variants : []
    );

    return (
        <div className={`${className} ${container}`}>
            {variants[0].businessMeaning !== 1 && (
                <>

                    <div className={selectWrapper}>
                        {options.map((option, index) => {
                            const filteredVariants = possibleVariants?.filter(
                                (array, variantIndex) => array.length > 0 && index !== variantIndex
                            );

                            const commonVariants =
                                filteredVariants && filteredVariants.length > 0
                                    ? filteredVariants.reduce((a, b) => a?.filter((c) => b?.includes(c)))
                                    : undefined;

                            const possibleOptions =
                                commonVariants && commonVariants?.length > 0
                                    ? option.values
                                        .map((value) =>
                                            value.variants.filter((variantId) =>
                                                commonVariants?.includes(variantId)
                                            ).length > 0
                                                ? value.valueId
                                                : 0
                                        )
                                        .flat(1)
                                    : option.values.map((value) => value.valueId);

                            const optionsList = option.values.map((element) => {
                                return {
                                    value: element.valueId,
                                    label: element.name,
                                    disabled: !possibleOptions.includes(element.valueId),
                                };
                            });

                            return (
                                <>
                                    <p className={selectLabel}>{option.name}</p>
                                    <ReactSelect
                                        key={index}
                                        className={reactSelect}
                                        classNamePrefix={'variant-select'}
                                        placeholder={option.name}
                                        options={optionsList}
                                        isOptionDisabled={(option) => option.disabled}
                                        onChange={(item) => {
                                            const selectedOption =
                                                option.values.find(
                                                    (element) => element.valueId === item?.value
                                                ) || options[0].values[0];
                                            handleClick(index, selectedOption);
                                        }}
                                    />
                                </>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
}
